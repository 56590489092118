header {
    padding: pcw(48) pcw(44) 0;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: pcw(16);
    @include sp {
        padding: spw(24) spw(10) 0;
    }
    h1 {
        margin-right: auto;
        width: pcw(160);
        height: pcw(24);
        @include sp {
            width: spw(133);
            height: spw(20);
        }
        path {
            fill: var(--c-m-brn);
            @include ts;
        }
    }
    .group-btn {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: pcw(20);
        .btn {
            width: pcw(120);
            height: pcw(40);
            line-height: pcw(40);
            @include btn($bg: var(--c-wht), $bc: var(--c-m-brn));
        }
        @include sp {
            gap: spw(20);
            .btn {
                width: spw(96);
                height: spw(32);
                line-height: spw(32);
            }
        }
    }
    .btn-hamburger {
        width: pcw(40);
        height: pcw(40);
        position: relative;
        span, &::before, &::after {
            @include content();
            width: pcw(20);
            height: 1px;
            background: var(--c-m-brn);
            left: pcw(10);
            @include ts;
        }
        &::before {
            top: pcw(14);
        }
        span {
            top: pcw(20);
        }
        &::after {
            top: pcw(26);
        }
        @include sp {
            width: spw(40);
            height: spw(40);
            span, &::before, &::after {
                width: spw(20);
                left: spw(10);
            }
            &::before {
                top: spw(14);
            }
            span {
                top: spw(20);
            }
            &::after {
                top: spw(26);
            }
        }
    }
    .gnav {
        padding: 0 pcw(176) 0 pcw(130);
        width: 100%;
        height: 100%;
        background: var(--c-c-beg);
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        gap: pcw(80);
        opacity: 0;
        visibility: hidden;
        @include ts;
        @include sp {
            padding: spw(106) spw(32) spw(23);
            flex-direction: column;
            align-items: flex-start;
            gap: 0;
            overflow-y: scroll;
        }
        .above {
            padding-right: pcw(600);
            width: pcw(900);
            position: relative;
            ul {
                min-height: pcw(300);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
            }
            .en, .jp {
                display: block;
                line-height: 1;
            }
            .en {
                margin-bottom: pcw(10);
                font-size: pcw(20);
                letter-spacing: .1em;
            }
            .jp {
                font-size: pcw(12);
                font-weight: 500;
                color: var(--c-gry);
            }
            .img {
                width: pcw(600);
                height: pcw(300);
                position: absolute;
                top: 0;
                right: 0;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    @include ts($duration: .8s);
                }
            }
            @include sp {
                margin-bottom: spw(40);
                padding-right: 0;
                width: initial;
                ul {
                    min-height: initial;
                    gap: spw(24);
                }
                .en {
                    font-size: spw(22);
                }
                .jp {
                    font-size: spw(12);
                }
                .img {
                    display: none;
                }
            }
        }
        .below {
            width: pcw(160);
            min-height: pcw(300);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            .group-btn {
                display: flex;
                flex-direction: column;
                gap: pcw(10);
                a {
                    width: pcw(144);
                    height: pcw(48);
                    line-height: pcw(48);
                    font-weight: 600;
                    display: block;
                }
            }
            .address {
                letter-spacing: .1em;
                a {
                    width: pcw(144);
                    height: pcw(48);
                    font-family: var(--ff-mar);
                    line-height: pcw(48);
                    letter-spacing: initial;
                    text-decoration: underline;
                    @include pc {
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
            @include sp {
                width: initial;
                min-height: initial;
                .group-btn {
                    margin-bottom: spw(24);
                    flex-direction: row;
                    justify-content: space-between;
                    gap: spw(24);
                    a {
                        width: spw(144);
                        height: spw(48);
                        line-height: spw(48);
                    }
                }
                .address {
                    margin-bottom: spw(14);
                }
            }
        }
    }
}
footer {
    padding: pcw(105) pcw(80);
    color: var(--c-wht);
    background: var(--c-m-brn);
    @include sp {
        padding: spw(48) spw(33);
    }
    @include pc {
        .above, .below {
            padding-left: pcw(260);
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
    .above {
        margin-bottom: pcw(50);
        h1 {
            width: pcw(213);
            height: pcw(32);
            position: absolute;
            top: 0;
            left: 0;
        }
        ul {
            margin-bottom: pcw(15);
            font-family: var(--ff-mar);
            letter-spacing: .1em;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: pcw(24);
        }
        .group-btn {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: pcw(40);
            .btn {
                font-size: pcw(16);
                font-weight: 600;
                color: var(--c-m-brn);
                @include btn($ff: var(--ff-serif), $bg: var(--c-wht), $bc: var(--c-wht));
            }
        }
        // @include sp(1300px) {
        //     padding-left: 0;
        //     flex-direction: column;
        //     justify-content: flex-start;
        //     h1 {
        //         margin-bottom: 55px;
        //         position: static;
        //     }
        //     ul {
        //         margin-bottom: 32px;
        //     }
        // }
        @include sp {
            margin-bottom: spw(32);
            h1 {
                margin-bottom: spw(20);
                width: spw(215);
                height: spw(33);
                position: static;
            }
            nav {
                margin-bottom: spw(30);
                ul {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: spw(16);
                    @include sp {
                        margin-bottom: spw(15);
                    }
                }
            }
            .group-btn {
                gap: spw(24);
                .btn {
                    width: spw(143);
                    font-size: spw(14);
                }
            }
        }
    }
    .below {
        .address {
            letter-spacing: .1em;
        }
        a {
            margin-left: 1em;
            font-family: var(--ff-mar);
            letter-spacing: initial;
            text-decoration: underline;
        }
        .copy {
            font-family: var(--ff-mar);
            letter-spacing: .1em;
        }
        // @include sp(1300px) {
        //     padding-left: 0;
        // }
        @include sp {
            flex-direction: column;
            .address {
                margin-bottom: spw(48);
            }
            a {
                margin-left: 0;
            }
            .copy {
                text-align: right;
            }
        }
    }
}