.ttl {
    .en {
        margin-bottom: pcw(24);
        font-size: pcw(32);
        font-family: var(--ff-mar);
        letter-spacing: .1em;
        display: block;
    }
    .jp {
        font-size: pcw(16);
        // font-weight: 600;
        letter-spacing: .2em;
        display: block;
    }
    @include sp {
        .en {
            margin-bottom: 0;
            font-size: spw(32);
        }
        .jp {
            font-size: spw(16);
        }
    }
}
.catch {
    font-size: pcw(32);
    letter-spacing: .2em;
    line-height: 2;
    @include sp {
        font-size: spw(24);
    }
}
.btn {
    width: pcw(200);
    height: pcw(56);
    line-height: pcw(56);
    letter-spacing: .1em;
    position: relative;
    @include btn;
    @include ts;
    span {
        position: relative;
        z-index: 1;
    }
    @include sp {
        width: spw(183);
        height: spw(56);
        line-height: spw(56);
    }
}
.link {
    position: relative;
    padding-bottom: pcw(5);
    &::after {
        @include content;
        width: 0;
        height: pcw(4);
        background: url(../img/obj/bg-red.png);
        left: 0;
        bottom: 0;
        @include ts;
    }
    @include pc {
        &:hover {
            &::after {
                width: 100%;
            }
        }
    }
}