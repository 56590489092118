// 4桁　動的に変わるもの
.intro {
    z-index: 9999;
}
.is-mask {
    z-index: 9000;
}
.is-modal {
    z-index: 9999;
}
// 3桁　メインのレイアウト
header {
    z-index: 999;
    h1, .group-btn, .btn-hamburger {
        z-index: 99;
    }
}
footer {
    position: relative;
    z-index: 800;
}
// 2桁　ブロック
// 1桁　単一パーツ