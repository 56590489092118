.intro {
    width: 100vw;
    height: 100vh;
    background: var(--c-wht);
    position: fixed;
    top: 0;
    left: 0;
    @include flex($h: center);
    @include ts($duration: .6s);
    img {
        width: pcw(162);
        height: pcw(120);
        opacity: 0;
        @include ts($duration: 1s);
        &.is-show {
            opacity: 1;
        }
        @include sp {
            width: spw(142);
            height: spw(104);
        }
    }
    &.is-hide {
        opacity: 0;
        pointer-events: none;
    }
}
.is-load {
    transition: opacity .3s, filter 2s;
    filter: brightness(3);
    opacity: 0;
    &.is-show {
        filter: brightness(1);
        opacity: 1;
    }
}
.is-header-chg {
    &.wht {
        header {
            h1 {
                path {
                    fill: var(--c-wht);
                }
            }
            .btn-hamburger {
                span, &::before, &::after {
                    background: var(--c-wht);
                }
            }
        }
    }
}
.is-menu-show {
    header {
        h1 {
            path {
                fill: var(--c-m-brn) !important;
            }
        }
        .btn-hamburger {
            span, &::before, &::after {
                background: var(--c-m-brn) !important;
                top: pcw(20);
            }
            &::before {
                transform: rotate(-30deg);
            }
            span {
                opacity: 0;
            }
            &::after {
                transform: rotate(30deg);
            }
            @include sp {
                span, &::before, &::after {
                    top: spw(20);
                }
            }
        }
        .gnav {
            opacity: 1;
            visibility: visible;
            .img {
                overflow: hidden;
                img {
                    &.is-hide {
                        opacity: 0;
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}
.is-header-fix {
    path {
        fill: var(--c-m-brn);
    }
    .btn-hamburger {
        span, &::before, &::after {
            background: var(--c-m-brn);
        }
    }
}
// .is-about-slider {
//     .slick-slide {
//         @include ts($delay: 1s);
//         transform: scale(.95);
//     }
//     .slick-active {
//         transform: scale(1);
//     }
// }
// .is-frame-img {
//     position: relative;
//     overflow: hidden;
//     img {
//         height: auto;
//         position: relative;
//         top: 0;
//         @include ts;
//     }
// }
@for $i from 0 through 5 {
    .is-catch {
        .line {
            &:nth-of-type(#{$i}) {
                transition-delay: calc($i * .3s);
            }
        }
    }
}
@for $i from 0 through 5 {
    .is-fadeslide {
        &:nth-of-type(#{$i}) {
            transition-delay: calc($i * .3s);
        }
    }
}
.is-scrframe {
    overflow: hidden;
    .is-scritem {
        width: 101%;
    }
}
.is-mask {
    @include ts;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    img {
        margin-bottom: 10px;
        width: initial;
        height: initial;
        max-width: 80vw;
        max-height: 80vh;
        border: 1px solid var(--c-wht);
    }
    &::before {
        @include content;
        width: 100%;
        height: 100%;
        background: var(--c-blk);
        opacity: 0.5;
        position: absolute;
        z-index: -1;
    }
    p {
        padding-top: 30px;
        font-size: 12px;
        color: var(--c-wht);
        position: relative;
        cursor: pointer;
        &::before, &::after {
            @include content;
            width: 30px;
            height: 1px;
            background: var(--c-wht);
            top: 15px;
            left: 5px;
        }
        &::before {
            transform: rotate(-45deg);
        }
        &::after {
            transform: rotate(45deg);
        }
    }
}
.is-modal-show {
    .is-mask {
        opacity: 1;
        pointer-events: initial;
    }
}
.is-catch {
    .line {
        display: block;
        opacity: 0;
        transform: translateX(30px);
        @include ts($duration: .8s);
        transition-timing-function: ease-out;
    }
    &.is-show {
        .line {
            opacity: 1;
            transform: translateX(0);
        }
    }
}
.is-hvscale {
    overflow: hidden;
    img {
        @include ts($duration: .6s);
    }
    @include pc {
        cursor: pointer;
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }
}
.is-fadeslide {
    opacity: 0;
    transform: translateX(30px);
    @include ts($duration: .8s);
    transition-timing-function: ease-out;
    &.is-show {
        opacity: 1;
        transform: translateX(0);
    }
}
.is-brightness {
    transition: opacity .3s, filter 2s;
    filter: brightness(2);
    opacity: 0;
    &.is-show {
        filter: brightness(1);
        opacity: 1;
    }
}
// .is-menu-slider {
//     .slick-current {
//         .img {
//             img {
//                 transform: scale(1.2);
//             }
//         }
//     }
// }

/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
.slick-slider {
    position: relative;
}
.slick-prev, .slick-next {
    width: spw(20);
    height: spw(20);
    font-size: 0;
    position: absolute;
    z-index: 1;
    &::after {
        @include content;
        width: 100%;
        height: 100%;
        border: 1px solid var(--c-m-brn);
        top: 0;
        left: 0;
    }
}
.slick-prev {
    &::after {
        border-right: none;
        border-bottom: none;
        transform: rotate(-45deg);
    }
}
.slick-next {
    &::after {
        border-bottom: none;
        border-left: none;
        transform: rotate(45deg);
    }
}
.slick-num {
    text-align: center;
    font-family: var(--ff-mar);
}
.slick-dots {
    @include flex;
    gap: pcw(40);
    li {
        width: 6px;
        height: 6px;
        font-size: 0;
        border-radius: 50%;
        background: var(--c-s-beg);
    }
    .slick-active {
        background: var(--c-m-brn);
    }
}