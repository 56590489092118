.page {
    &-index {
        .ttl {
            margin-bottom: pcw(80);
            @include sp {
                margin-bottom: spw(64);
            }
            h2 {
                font-size: pcw(32);
                font-family: var(--ff-mar);
                letter-spacing: .1em;
                @include sp {
                    font-size: spw(32);
                }
            }
            .summary {
                font-family: var(--ff-serif);
                .en {
                    font-size: pcw(16);
                    font-family: var(--ff-mar);
                    letter-spacing: .2em;
                    display: inline;
                    &.bar {
                        padding-right: pcw(78);
                        position: relative;
                        &::after {
                            @include content;
                            width: pcw(40);
                            height: 1px;
                            background: var(--c-m-brn);
                            top: 50%;
                            right: pcw(16);
                        }
                    }
                }
                .jp {
                    display: inline;
                }
                @include sp {
                    .en {
                        font-size: spw(16);
                        &.bar {
                            padding-right: spw(78);
                            font-size: spw(16);
                            &::after {
                                width: spw(40);
                                right: spw(16);
                            }
                        }
                    }
                }
            }
        }
        .hero {
            padding-left: pcw(78);
            width: 100%;
            height: 100vh;
            color: var(--c-wht);
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            @include sp {
                padding-left: spw(32);
            }
            h2 {
                position: relative;
                z-index: 1;
            }
            .jp {
                margin-bottom: pcw(16);
                font-size: pcw(32);
                font-family: var(--ff-serif);
                line-height: 1.75;
                letter-spacing: .2em;
                display: block;
                @include sp {
                    margin-bottom: spw(16);
                    font-size: spw(32);
                }
            }
            .en {
                font-size: pcw(16);
                font-family: var(--ff-mar);
                letter-spacing: .1em;
                display: block;
                @include sp {
                    font-size: spw(16);
                }
            }
            .bg {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
            }
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .full {
                width: pcw(347);
                height: pcw(120);
                letter-spacing: .2em;
                color: var(--c-s-beg);
                background: var(--c-m-brn);
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: absolute;
                left: 0;
                bottom: 0;
                @include sp {
                    width: spw(280);
                    height: spw(97);
                }
            }
            .txt {
                padding-left: pcw(30);
                width: pcw(120);
                font-family: var(--ff-mar);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                @include sp {
                    padding-left: spw(25);
                    width: spw(97);
                }
            }
            .img {
                width: calc(100% - pcw(120));
                height: 100%;
                position: relative;
                img {
                    object-fit: cover;
                }
                &::after {
                    @include content;
                    width: pcw(46);
                    height: pcw(32);
                    background: url(../img/ico/movie.svg) no-repeat center center;
                    background-size: contain;
                    top: calc(50% - pcw(21));
                    left: calc(50% - pcw(23));
                }
                @include sp {
                    width: calc(100% - spw(97));
                    &::after {
                        width: spw(26);
                        height: spw(20);
                        top: calc(50% - spw(13));
                        left: calc(50% - spw(10));
                    }
                }
            }
        }
        .about {
            padding: pcw(200) 0;
            background: var(--c-c-beg);
            position: relative;
            @include sp {
                padding: spw(104) 0;
            }
            .ttl {
                margin-bottom: pcw(105);
                padding-left: pcw(200);
                @include sp {
                    margin-bottom: spw(80);
                    padding-left: spw(30);
                }
            }
            .float {
                width: pcw(360);
                height: pcw(360);
                position: absolute;
                top: pcw(200);
                right: pcw(80);
                // @include sp(1300px) {
                //     width: pcw(300);
                //     height: pdw(300);
                //     right: 0;
                // }
                @include sp {
                    margin-right: spw(32);
                    margin-left: auto;
                    margin-bottom: spw(64);
                    width: spw(160);
                    height: spw(160);
                    position: static;
                }
            }
            .wrap {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                @include sp {
                    flex-direction: column;
                }
            }
            .img {
                width: 50%;
                @include sp {
                    margin-bottom: spw(64);
                    width: 100%;
                }
            }
            .txt {
                padding-left: pcw(140);
                width: 50%;
                line-height: 3;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: pcw(56);
                @include sp {
                    padding: 0 spw(32);
                    width: 100%;
                    gap: spw(48);
                }
            }
            .btn {
                @include sp {
                    margin-left: auto;
                }
            }
        }
        .gallery {
            img {
                border-right: 1px solid var(--c-wht);
            }
        }
        .movie {
            padding: pcw(136) 0;
            background: var(--c-wht);
            .inner {
                margin: 0 auto;
                width: pcw(920);
            }
            .frame-movie {
                margin-bottom: pcw(64);
                @include pc {
                    &::before {
                        content: "CORPORATE MOVIE";
                        width: pcw(186);
                        height: pcw(48);
                        line-height: pcw(48);
                        letter-spacing: 1.1;
                        text-align: center;
                        font-family: var(--ff-mar);
                        color: var(--c-blk);
                        background: var(--c-wht);
                        transform-origin: right top;
                        transform: rotate(90deg);
                        position: absolute;
                        top: pcw(186);
                        right: 0;
                        pointer-events: none;
                    }
                }
                &::after {
                    @include content;
                    width: pcw(92);
                    height: pcw(64);
                    background: url(../img/ico/movie.svg) no-repeat center center;
                    background-size: contain;
                    top: calc(50% - pcw(46));
                    left: calc(50% - pcw(32));
                    pointer-events: none;
                }
            }
            .catch {
                text-align: center;
            }
            @include sp {
                padding: spw(104) 0;
                .inner {
                    width: spw(319);
                }
                .frame-movie {
                    margin: 0 auto spw(48);
                    width: spw(247);
                }
            }
        }
        .brand {
            padding: pcw(104) pcw(80);
            background: var(--c-c-beg);
            text-align: center;
            @include sp {
                padding: spw(80) 0;
            }
            .list {
                @include pc {
                    margin-bottom: pcw(80);
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: pcw(80);
                }
                @include sp {
                    margin-bottom: spw(40);
                    // gap: 0 spw(48);
                }
            }
            .btn {
                margin: 0 auto;
            }
            .card {
                padding-bottom: pcw(40);
                width: calc((100% - pcw(160)) / 3);
                text-align: left;
                position: relative;
                @include sp {
                    margin: 0 spw(24);
                    width: spw(247);
                    text-align: center;
                }
            }
            .img {
                margin-bottom: pcw(36);
                @include sp {
                    margin-bottom: spw(28);
                }
            }
            .catch {
                margin-bottom: pcw(24);
                font-size: pcw(20);
                font-weight: 500;
                @include sp {
                    margin-bottom: spw(20);
                    font-size: spw(18);
                    font-weight: 600;
                }
            }
            .name {
                line-height: pcw(40);
                font-size: pcw(12);
                font-weight: 600;
                // border-bottom: 2px dotted var(--c-s-beg);
                position: absolute;
                left: 0;
                bottom: 0;
                &::before {
                    @include content;
                    width: 100%;
                    height: pcw(2);
                    background: repeating-linear-gradient(90deg, transparent 0, transparent 2px, var(--c-s-beg) 2px, var(--c-s-beg) 3px);
                    left: 0;
                    bottom: 0;
                }
                @include sp {
                    line-height: spw(30);
                    position: relative;
                    display: inline-block;
                    font-size: spw(12);
                    &::before {
                        height: spw(2);
                    }
                }
            }
            .near {
                padding-bottom: 2px;
                line-height: pcw(40);
                font-size: pcw(12);
                font-weight: 500;
                position: absolute;
                right: 0;
                bottom: 0;
                &::before {
                    content: "Near / ";
                    font-family: var(--ff-mar);
                }
                @include sp {
                    line-height: spw(40);
                    position: static;
                    font-size: spw(12);
                }
            }
            @include sp {
                .slick-prev, .slick-next {
                    bottom: spw(130);
                }
                .slick-prev {
                    left: spw(35);
                }
                .slick-next {
                    right: spw(35);
                }
            }
        }
        .news {
            padding: pcw(110) pcw(160) pcw(98);
            background: var(--c-beg);
            @include sp {
                padding: spw(80) spw(32);
            }
            .ttl {
                @include sp {
                    margin-bottom: spw(64);
                    text-align: center;
                }
            }
            .list {
                @include pc {
                    margin-bottom: pcw(80);
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: pcw(80) pcw(57);
                }
                @include sp {
                    margin-bottom: spw(40);
                }
            }
            .card {
                padding-bottom: pcw(30);
                width: calc((100% - pcw(114)) / 3);
                position: relative;
                align-self: stretch;
                @include sp {
                    margin-bottom: spw(52);
                    padding-bottom: 0;
                    width: 100%;
                }
                .data {
                    margin-bottom: pcw(40);
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: pcw(16);
                    @include sp {
                        margin-bottom: spw(24);
                    }
                }
                .date {
                    font-size: pcw(16);
                    font-family: var(--ff-mar);
                    @include sp {
                        margin-right: spw(16);
                        font-size: spw(16);
                    }    
                }
                .category {
                    padding: 0 5px;
                    height: pcw(30);
                    line-height: pcw(30);
                    font-family: var(--ff-mar);
                    background: var(--c-c-beg);
                    border: 1px solid var(--c-s-beg);
                    border-radius: 2px;
                    @include sp {
                        height: spw(30);
                        line-height: spw(30);
                    }
                }
                .ttl {
                    margin-bottom: pcw(10);
                    font-size: pcw(16);
                    font-weight: 600;
                    letter-spacing: .1em;
                    @include sp {
                        margin-bottom: spw(16);
                        font-size: spw(16);
                        text-align: left;
                    }
                }
                .txt {
                    margin-bottom: pcw(48);
                    font-size: pcw(16);
                    @include sp {
                        margin-bottom: spw(32);
                        font-size: spw(12);
                    }    
                }
                .link {
                    font-family: var(--ff-mar);
                    letter-spacing: .1em;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    &::before {
                        @include content;
                        width: 100%;
                        height: pcw(4);
                        background: var(--c-s-beg);
                        left: 0;
                        bottom: 0;
                    }
                    @include sp {
                        position: relative;
                        &::before {
                            height: spw(4);
                            bottom: spw(-8);
                        }
                    }
                }
            }
            .btn {
                margin: 0 auto;
                @include sp {
                    margin: 0 auto;
                }
            }
            @include sp {
                .slick-prev, .slick-next {
                    bottom: spw(5);
                }
                .slick-prev {
                    left: spw(10);
                }
                .slick-next {
                    right: spw(10);
                }
            }
        }
        .recruit {
            padding: pcw(200) 0 pcw(136);
            background: var(--c-wht);
            @include sp {
                padding: spw(104) 0;
            }
            .inner {
                margin: 0 auto;
                width: pcw(1080);
                @include pc {
                    display: grid;
                    grid-template-columns: 1fr pcw(460);
                    grid-template-rows: 1fr auto 1fr;
                    gap: pcw(56);
                }
                @include sp {
                    width: spw(311);
                }
            }
            .ttl {
                @include pc {
                    margin-bottom: 0;
                    grid-column: 1;
                    grid-row: 1;
                    align-self: end;
                }
            }
            .txt {
                @include pc {
                    grid-column: 1;
                    grid-row: 2;
                }
                @include sp {
                    margin-bottom: spw(48);
                }
            }
            .group-btn {
                display: flex;
                justify-content: flex-start;
                gap: pcw(20);
                .btn {
                    font-weight: 600;
                }
                @include pc {
                    grid-column: 1;
                    grid-row: 3;
                }
                @include sp {
                    gap: spw(24);
                }
            }
            .img {
                @include pc {
                    grid-column: 2;
                    grid-row: 1 / 4;
                }
                @include sp {
                    margin-bottom: spw(48);
                }
            }
        }
        .company {
            background: var(--c-wht);
            .inner {
                @include pc {
                    display: grid;
                    grid-template-columns: pcw(680) 1fr;
                    grid-template-rows: pcw(105) auto auto auto auto pcw(105);
                    gap: 0 pcw(160);
                }
                @include sp {
                    margin: 0 auto;
                    padding-bottom: spw(104);
                    width: spw(311);
                }
            }
            .ttl {
                @include pc {
                    grid-column: 2;
                    grid-row: 2;
                    align-self: end;
                }
            }
            .catch {
                margin-bottom: pcw(32);
                font-size: pcw(24);
                @include pc {
                    grid-column: 2;
                    grid-row: 3;
                }
                @include sp {
                    margin-bottom: spw(48);
                    font-size: spw(24);
                }
            }
            .txt {
                margin-bottom: pcw(56);
                line-height: 2;
                @include pc {
                    grid-column: 2;
                    grid-row: 4;
                }
                @include sp {
                    margin-bottom: spw(48);
                }
            }
            .btn {
                @include pc {
                    grid-column: 2;
                    grid-row: 5;
                }
            }
            .img {
                img {
                    object-fit: cover;
                }
                @include pc {
                    grid-column: 1;
                    grid-row: 1 / 7;
                }
                @include sp {
                    margin-left: spw(-32);
                    margin-bottom: spw(48);
                    width: calc(100% + spw(64));
                }
            }
        }
    }
    &-brand {
        .head {
            padding: pcw(182) pcw(82) pcw(56);
            background: url(../img/bg/head.png);
            @include sp {
                padding: spw(120) spw(32) spw(40);
            }
        }
        .list {
            .block {
                margin: 0 auto;
                padding: pcw(64) 0;
                width: pcw(1240);
                @include flex($h: flex-start);
                gap: pcw(120);
                &:not(:last-child) {
                    border-bottom: 1px solid var(--c-s-beg);
                }
            }
            .img {
                width: pcw(560);
                height: pcw(375);
            }
            .txt {
                width: pcw(480);
                .info {
                    margin-bottom: pcw(32);
                }
                .catch {
                    margin-bottom: pcw(20);
                }
                .near {
                    &::before {
                        content: "Near / ";
                        font-family: var(--ff-mar);
                    }
                }
            }
            @include sp {
                .block {
                    padding: 0;
                    width: 100%;
                    display: block;
                    &:not(:last-child) {
                        border: none;
                    }
                }
                .img {
                    width: 100%;
                    height: initial;
                }
                .txt {
                    padding: spw(40) spw(28) spw(64);
                    width: 100%;
                    .catch {
                        margin-bottom: spw(24);
                    }
                    .info {
                        margin-bottom: spw(24);
                    }
                    .btn {
                        margin-left: auto;
                    }
                }
            }
        }
        .detail {
            .mv {
                padding: pcw(144) pcw(20) 0;
                overflow: hidden;
                &::after {
                    @include content;
                    width: 100%;
                    height: pcw(485);
                    // background: var(--c-c-beg);
                    background: url(../img/bg/head.png);
                    top: 0;
                    left: 0;
                }
                .wrap {
                    // width: pcw(1460);
                    height: pcw(560);
                    @include flex;
                    position: relative;
                    z-index: 1;
                    picture {
                        width: 100%;
                        height: 100%;
                        img {
                            object-fit: cover;
                        }
                    }
                    h2 {
                        font-size: pcw(32);
                        font-weight: 500;
                        letter-spacing: .1em;
                    }
                    .bg {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
                @include sp {
                    padding: spw(80) spw(20) 0;
                    &::after {
                        height: spw(248);
                    }
                    .wrap {
                        height: initial;
                        h2 {
                            margin-bottom: spw(48);
                            font-size: spw(24);
                        }
                        .bg {
                            margin-bottom: spw(32);
                            height: initial;
                            position: static;
                        }
                    }
                }
            }
            .above {
                padding: pcw(35) pcw(64);
                @include flex;
                .name {
                    font-size: pcw(32);
                    font-weight: 600;
                    letter-spacing: .1em;
                }
                .anchor {
                    letter-spacing: .1em;
                    font-family: var(--ff-mar);
                    @include flex;
                    gap: pcw(24);
                }
                @include sp {
                    flex-direction: column;
                    align-items: flex-start;
                    .name {
                        margin-bottom: spw(16);
                        font-size: spw(16);
                    }
                    .anchor {
                        li {
                            width: 48%;
                            font-size: spw(12);
                        }
                    }
                }
            }
            .concept {
                margin: 0 auto;
                padding: pcw(136) 0;
                width: pcw(920);
                display: grid;
                grid-template:
                    repeat(5, auto) /
                    pcw(460) 1fr;
                gap: 0 pcw(40);
                h3 {
                    margin-bottom: pcw(24);
                    border-bottom: 2px dotted var(--c-m-brn);
                    font-family: var(--ff-mar);
                    display: inline-block;
                    grid-row: 2;
                    grid-column: 2;
                    justify-self: start;
                }
                .img {
                    grid-row: 1 / span 5;
                    grid-column: 1;
                }
                .catch {
                    margin-bottom: pcw(48);
                    grid-row: 3;
                    grid-column: 2;
                }
                .txt {
                    grid-row: 4;
                    grid-column: 2;
                }
                @include sp {
                    padding: spw(104) 0;
                    width: spw(315);
                    display: block;
                    h3 {
                        margin: 0 auto spw(48);
                        font-size: spw(12);
                    }
                    .img {
                        margin: 0 auto spw(32);
                        width: spw(247);
                    }
                    .catch {
                        margin-bottom: spw(32);
                        font-size: spw(24);
                    }
                }
            }
            .menu {
                margin: -100px auto pcw(200);
                padding-top: 100px;
                width: pcw(920);
                .block {
                    padding-left: 5px;
                    display: grid;
                    grid-template:
                        repeat(5, auto) /
                        1fr pcw(400);
                    gap: 0 pcw(20);
                    h3 {
                        margin-bottom: pcw(24);
                        border-bottom: 2px dotted var(--c-m-brn);
                        font-family: var(--ff-mar);
                        letter-spacing: .2em;
                        display: inline-block;
                        grid-row: 2;
                        grid-column: 1;
                        justify-self: start;
                    }
                    .img {
                        grid-row: 1 / span 5;
                        grid-column: 2;
                        img {
                            @include ts($delay: .2s, $duration: 1s);
                        }
                    }
                    .catch {
                        margin-bottom: pcw(48);
                        grid-row: 3;
                        grid-column: 1;
                    }
                    .txt {
                        grid-row: 4;
                        grid-column: 1;
                    }
                }
                @include sp {
                    margin-bottom: spw(104);
                    width: 100%;
                    .block {
                        padding-left: 0;
                        display: block;
                        h3 {
                            margin: 0 spw(28) spw(48);
                        }
                        .img {
                            margin-bottom: spw(60);
                            width: 100vw;
                            height: 100vw;
                            overflow: hidden;
                            img {
                                object-fit: cover;
                            }
                        }
                        .catch {
                            margin: 0 spw(28) spw(32);
                            font-size: spw(20);
                        }
                        .txt {
                            margin: 0 spw(28);
                        }
                    }
                }
                .slick-prev, .slick-next {
                    width: pcw(25);
                    height: pcw(25);
                    // top: calc(50% - pcw(50));
                    top: pcw(320);
                }
                .slick-prev {
                    left: pcw(-80);
                }
                .slick-next {
                    right: pcw(-80);
                }
                .slick-dots {
                    margin-right: auto;
                    width: pcw(400);
                    justify-content: flex-start;
                }
                @include sp {
                    .slick-dots {
                        width: 100%;
                        position: absolute;
                        top: spw(570);
                        justify-content: center;
                        gap: spw(30);
                    }
                }
            }
            .information {
                margin: -100px auto pcw(124);
                padding-top: 100px;
                width: pcw(760);
                h3 {
                    margin-bottom: pcw(66);
                    text-align: center;
                    span {
                        font-size: pcw(20);
                        font-family: var(--ff-mar);
                        border-bottom: 2px dotted var(--c-m-brn);
                    }
                }
                li {
                    padding: pcw(20) 0;
                    &:not(:last-child) {
                        border-bottom: 2px dotted var(--c-s-beg);
                    }
                    @include flex;
                    p {
                        font-size: pcw(20);
                    }
                }
                @include sp {
                    margin-bottom: spw(64);
                    width: spw(311);
                    h3 {
                        margin-bottom: spw(52);
                        text-align: left;
                        span {
                            font-size: spw(12);
                        }
                    }
                    li {
                        padding: spw(24) 0;
                        flex-direction: column;
                        align-items: flex-start;
                        p {
                            margin-bottom: spw(10);
                            font-size: spw(20);
                        }
                    }
                }
            }
            .gallery {
                padding: pcw(148) 0 pcw(60);
                text-align: center;
                background: var(--c-c-beg);
                h3 {
                    margin-bottom: pcw(66);
                    span {
                        font-size: pcw(20);
                        border-bottom: 2px dotted var(--c-m-brn);
                        font-family: var(--ff-mar);
                    }
                }
                ul {
                    margin: 0 auto pcw(100);
                    width: pcw(1080);
                    @include flex($h: flex-start);
                    gap: pcw(36);
                    li {
                        width: calc((100% - pcw(72)) / 3);
                        height: pcw(340);
                        a {
                            cursor: pointer;
                        }
                        img {
                            object-fit: cover;
                        }
                    }
                }
                @include sp {
                    padding: spw(64) spw(27) spw(20);
                    text-align: left;
                    h3 {
                        margin-bottom: spw(45);
                        span {
                            font-size: spw(12);
                        }
                    }
                    ul {
                        margin: 0 auto spw(40);
                        width: 100%;
                        gap: spw(24);
                        li {
                            width: calc((100% - spw(48)) / 2);
                        }
                    }
                }
            }
            .back {
                padding: pcw(100) 0 pcw(200);
                text-align: center;
                background: var(--c-c-beg);
                display: block;
                .link {
                    font-size: pcw(48);
                    font-family: var(--ff-mar);
                    color: var(--c-gry);
                    letter-spacing: .1em;
                    &::before {
                        @include content;
                        width: 100%;
                        height: pcw(4);
                        background: var(--c-s-beg);
                        left: 0;
                        bottom: 0;
                        @include ts;
                    }
                }
                @include sp {
                    padding-bottom: spw(80);
                    .link {
                        font-size: spw(24);
                        text-align: center;
                    }
                }
            }
        }
    }
    &-news {
        .head {
            padding: pcw(182) pcw(82) pcw(56);
            background: url(../img/bg/head.png);
            @include sp {
                padding: spw(120) spw(32) spw(40);
            }
        }
        .list {
            margin: 0 auto;
            padding: pcw(104) 0;
            width: pcw(1080);
            @include flex($h: flex-start, $v: flex-start);
            gap: pcw(40);
            @include sp {
                width: spw(311);
                gap: spw(24);
            }
            .block {
                padding: pcw(40);
                width: calc((100% - pcw(80)) / 2);
                border: 1px solid var(--c-s-beg);
                align-self: stretch;
                @include sp {
                    width: 100%;
                }
                .data {
                    margin-bottom: pcw(20);
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: pcw(32);
                    @include sp {
                        margin-bottom: 0;
                        display: block;
                    }
                }
                .date {
                    font-size: pcw(14);
                    font-family: var(--ff-mar);
                    @include sp {
                        margin-right: spw(16);
                        margin-bottom: spw(32);
                        font-size: spw(16);
                    }    
                }
                .category {
                    padding: 0 5px;
                    height: pcw(32);
                    font-size: pcw(14);
                    font-weight: 500;
                    line-height: pcw(32);
                    font-family: var(--ff-mar);
                    border: 1px solid var(--c-s-beg);
                    border-radius: 2px;
                    @include sp {
                        margin-bottom: spw(16);
                        height: spw(30);
                        font-size: spw(14);
                        line-height: spw(30);
                        display: inline-block;
                    }
                }
                .ttl {
                    margin-bottom: pcw(16);
                    font-size: pcw(20);
                    font-weight: 600;
                    letter-spacing: .1em;
                    @include sp {
                        margin-bottom: spw(16);
                        font-size: spw(20);
                        text-align: left;
                    }
                }
                .txt {
                    margin-bottom: pcw(48);
                    font-size: pcw(16);
                    @include sp {
                        margin-bottom: spw(32);
                        font-size: spw(12);
                    }    
                }
                .link {
                    font-family: var(--ff-mar);
                    letter-spacing: .1em;
                    &::before {
                        @include content;
                        width: 100%;
                        height: pcw(4);
                        background: var(--c-s-beg);
                        left: 0;
                        bottom: 0;
                    }
                    @include sp {
                        margin-bottom: spw(5);
                        padding-bottom: spw(5);
                        display: inline-block;
                        &::before {
                            height: spw(4);
                        }
                    }
                }
            }
        }
        .detail {
            margin: 0 auto;
            padding: pcw(104) 0;
            width: pcw(760);
            word-break: break-all;
            font-size: pcw(16);
            letter-spacing: .1em;
            .wp-block-table {
                & + .wp-block-table {
                    margin-top: 20px;
                }
            }
            p {
                margin-bottom: pcw(104);
            }
            img {
                margin: 0 auto;
                max-width: pcw(600);
                display: block;
            }
            .back {
                padding: pcw(100) 0 pcw(200);
                text-align: center;
                display: block;
                .link {
                    font-size: pcw(48);
                    font-family: var(--ff-mar);
                    color: var(--c-gry);
                    letter-spacing: 0.1em;
                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: pcw(4);
                        background: var(--c-s-beg);
                        left: 0;
                        bottom: 0;
                        transition: all 0.3s 0s;
                    }
                }
            }
            @include sp {
                padding: spw(64) 0;
                width: spw(311);
                font-size: spw(16);
                .wp-block-table {
                    overflow-x: scroll;
                    table {
                        white-space: nowrap;
                    }
                }
                p {
                    margin-bottom: spw(64);
                }
                img {
                    max-width: spw(311);
                }
                .back {
                    padding-bottom: spw(80);
                    .link {
                        font-size: spw(24);
                        text-align: center;
                    }
                }
            }
        }
    }
    &-about, &-company {
        .ttl-img {
            margin-right: auto;
            padding-left: pcw(240);    
            width: pcw(1080);
            height: pcw(304);
            font-size: pcw(32);
            letter-spacing: .1em;
            color: var(--c-wht);
            font-family: var(--ff-mar);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            @include sp {
                margin: 0 0 spw(80);
                padding-left: spw(32);
                width: 100%;
                height: spw(160);
                font-size: spw(24);
            }
        }
        .hero {
            position: relative;
            z-index: 1;
            .above {
                padding-top: pcw(168);
                padding-left: pcw(80);
                min-height: pcw(540);
                background: url(../img/bg/head.png);
                position: relative;
                .mv {
                    @include content;
                    width: pcw(1080);
                    height: pcw(540);
                    // background-image: url(../img/about/mv-pc.png);
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    right: 0;
                    bottom: pcw(-160);
                }
                @include sp {
                    padding: spw(100) 0 0;
                    min-height: initial;
                    .ttl {
                        padding: spw(32) spw(40);
                    }
                    .mv {
                        width: 100%;
                        height: spw(282);
                        position: static;
                    }
                }
            }
            .below {
                padding: pcw(80) 0 pcw(200);
                background: var(--c-wht);
                .anchor {
                    margin-left: pcw(80);
                    font-family: var(--ff-mar);
                }
                .wrap {
                    margin: 0 auto;
                    padding-top: pcw(200);
                    width: pcw(960);
                    font-weight: 500;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .catch {
                    width: pcw(500);
                    font-size: pcw(32);
                }
                .txt {
                    width: pcw(400);
                    line-height: 2.5;
                }
                @include sp {
                    padding: spw(32) spw(32) spw(100);
                    overflow: hidden;
                    .anchor {
                        margin: 0 0 spw(104);
                    }
                    .wrap {
                        padding: 0;
                        width: 100%;
                        flex-direction: column;
                        gap: spw(48);
                    }
                    .catch {
                        width: 100%;
                        font-size: spw(24);
                    }
                    .txt {
                        width: 100%;
                    }
                }
            }
        }
        .next {
            padding: pcw(64) pcw(220);
            font-family: var(--ff-mar);
            letter-spacing: .1em;
            color: var(--c-gry);
            background: var(--c-beg);
            border: 20px solid var(--c-wht);
            display: grid;
            grid-template-columns: pcw(520) pcw(400);
            grid-template-rows: 1fr auto auto 1fr;
            position: relative;
            z-index: 1;
            .head {
                margin-bottom: pcw(24);
                font-size: pcw(16);
                grid-column: 1;
                grid-row: 2;
            }
            a {
                font-size: pcw(32);
                grid-column: 1;
                grid-row: 3;
                span {
                    &::before {
                        @include content;
                        width: 100%;
                        height: pcw(4);
                        background: var(--c-s-beg);
                        left: 0;
                        bottom: 0;
                        @include ts;
                    }
                }
            }
            .img {
                width: pcw(400);
                height: pcw(267);
                grid-column: 2;
                grid-row: 1 / 5;
            }
            @include sp {
                margin: 0 auto;
                padding: spw(48) spw(64);
                text-align: center;
                display: block;
                border-width: spw(32);
                .head {
                    margin-bottom: spw(32);
                    font-size: spw(16);
                }
                .img {
                    margin-bottom: spw(32);
                    width: 100%;
                    height: initial;
                }
                a {
                    font-size: spw(24);
                }
            }
        }
    }
    &-about {
        .bg {
            width: 100%;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            img, video {
                object-fit: cover;
            }
        }
        .hero {
            .above {
                // .mv {
                //     background-image: url(../img/about/mv-pc.png);
                //     @include sp {
                //         background-image: url(../img/about/mv-sp.png);
                //     }
                // }
            }
            .below {
                @include sp {
                    .catch {
                        width: calc(100% + spw(36));
                    }
                }
            }
        }
        .concept {
            padding-bottom: pcw(280);
            background: var(--c-wht);
            position: relative;
            z-index: 1;
            @include sp {
                padding-bottom: spw(104);
            }
            .ttl-img {
                margin-bottom: pcw(136);
                position: relative;
                h2 {
                    position: relative;
                    z-index: 1;
                }
                .img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
                // background-image: url(../img/about/concept-img01.png);
                @include sp {
                    margin-bottom: spw(80);
                }
            }
            .block {
                margin: 0 auto;
                width: pcw(920);
                display: flex;
                justify-content: space-between;
                align-items: center;
                .img {
                    width: pcw(400);
                }
                .double{
                    @include flex;
                    img {
                        // width: calc(50% - pcw(10));
                        &:nth-of-type(2) {
                            margin-top: pcw(100);
                        }
                    }
                }
                .txt {
                    width: pcw(400);
                    line-height: 2.5;
                }
                .catch {
                    margin-bottom: pcw(60);
                    font-size: pcw(20);
                    font-weight: 600;
                }
                &:not(:last-child) {
                    margin-bottom: pcw(136);
                }
                @include pc {
                    &:nth-of-type(odd) {
                        flex-direction: row-reverse;
                        .img {
                            width: pcw(400);
                            height: pcw(440);
                            position: relative;
                            div {
                                width: pcw(190);
                                height: pcw(338);
                                position: absolute;
                                &:nth-of-type(1) {
                                    top: 0;
                                    left: 0;
                                }
                                &:nth-of-type(2) {
                                    top: pcw(120);
                                    right: 0;
                                }
                            }
                        }
                        .txt {
                            width: pcw(400);
                            line-height: 2.5;
                        }
                    }
                }
                @include sp {
                    width: spw(311);
                    flex-direction: column;
                    .img {
                        margin: 0 auto spw(48);
                        width: spw(247);
                    }
                    .txt {
                        width: 100%;
                    }
                    .catch {
                        margin-bottom: spw(48);
                        font-size: spw(16);
                    }
                    &:not(:last-child) {
                        margin-bottom: spw(64);
                    }
                    .img {
                        width: 100%;
                        img {
                            margin: 0 5px;
                            // width: spw(140);
                            height: auto;
                            &:nth-of-type(even) {
                                margin-top: spw(80);
                            }
                        }
                    }
                    .double {
                        div {
                            width: calc(50% - spw(10));
                            &:nth-of-type(2) {
                                margin-top: spw(100);
                            }
                        }
                    }
                }
            }
        }
        .commit {
            margin: 0;
            color: var(--c-wht);
            position: relative;
            z-index: 1;
            @include sp {
                margin: 0;
                padding: 0 0 spw(80);
            }
            .ttl-img {
                margin-bottom: pcw(160);
                // background-image: url(../img/about/commit-img01.png);
                position: relative;
                top: pcw(-80);
                h2 {
                    position: relative;
                    z-index: 1;
                }
                .img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
                @include sp {
                    margin-bottom: spw(80);
                }
            }
            h3 {
                margin: 0 auto pcw(200) pcw(240);
                width: pcw(600);
                font-size: pcw(32);
                span {
                    display: block;
                    @include pc {
                        &:nth-of-type(2) {
                            margin-left: 1em;
                        }
                        &:nth-of-type(3) {
                            margin-left: 2em;
                        }
                    }
                }
                @include sp {
                    margin: 0 auto spw(104) spw(60);
                    width: spw(260);
                    font-size: spw(24);
                }
            }
            .wrap {
                margin: 0 auto pcw(200);
                width: pcw(920);
                display: flex;
                justify-content: space-between;
                align-items: center;
                .txt {
                    width: pcw(460);
                    line-height: 2.5;
                    h4 {
                        font-size: pcw(32);
                        font-family: var(--ff-mar);
                    }
                    p {
                        font-weight: 600;
                    }
                }
                .img {
                    width: pcw(460);
                    position: relative;
                    a {
                        display: block;
                    }
                    &::after {
                        @include content;
                        width: pcw(58);
                        height: pcw(40);
                        background: url(../img/ico/movie.svg) no-repeat center center;
                        background-size: contain;
                        top: calc(50% - pcw(29));
                        left: calc(50% - pcw(20));
                        pointer-events: none;
                    }
                }
                @include sp {
                    margin: 0 auto;
                    padding: 0 spw(60);
                    width: 100%;
                    flex-direction: column;
                    .txt {
                        margin-bottom: spw(48);
                        width: 100%;
                        h4 {
                            margin-bottom: spw(24);
                            font-size: spw(24);
                        }
                    }
                    .img {
                        width: 100%;
                        &::after {
                            width: spw(29);
                            height: spw(20);
                            top: calc(50% - spw(14));
                            left: calc(50% - spw(10));
                        }
                    }
                }
            }
        }
        .philosophy {
            padding-bottom: pcw(104);
            background: var(--c-wht);
            position: relative;
            z-index: 1;
            .ttl-img {
                margin-bottom: pcw(136);
                // background-image: url(../img/about/philosophy-img01.png);
                position: relative;
                top: pcw(-80);
                h2 {
                    position: relative;
                    z-index: 1;
                }
                .img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                }
                @include sp {
                    margin-bottom: spw(80);
                    top: 0;
                }
            }
            .block {
                margin: 0 auto;
                width: pcw(920);
                display: flex;
                justify-content: space-between;
                align-items: center;
                &:not(:last-child) {
                    margin-bottom: pcw(64);
                }
                .img {
                    width: pcw(320);
                }
                .txt {
                    width: pcw(520);
                    .head {
                        margin-bottom: pcw(32);
                        font-size: pcw(12);
                        font-family: var(--ff-mar);
                        border-bottom: 2px dotted var(--c-brn);
                        display: inline-block;
                    }
                    .ttl {
                        margin-bottom: pcw(24);
                        font-size: pcw(20);
                    }
                }
                @include sp {
                    width: spw(311);
                    flex-direction: column;
                    &:not(:last-child) {
                        margin-bottom: spw(64);
                    }
                    .img {
                        margin: 0 auto spw(48);
                        width: spw(247);
                    }
                    .txt {
                        width: 100%;
                        .head {
                            margin-bottom: spw(32);
                            font-size: spw(12);
                        }
                        .ttl {
                            margin-bottom: spw(24);
                            font-size: spw(20);
                        }
                    }
                }
            }
            .movie {
                margin: 0 auto;
                width: pcw(920);
                color: var(--c-wht);
                background: var(--c-m-brn);
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                align-items: center;
                .img {
                    width: pcw(400);
                    position: relative;
                    &::before {
                        @include content;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, .4);
                        top: 0;
                        left: 0;
                        z-index: 1;
                        @include ts;
                    }
                    &::after {
                        @include content;
                        width: pcw(46);
                        height: pcw(32);
                        background: url(../img/ico/movie.svg) no-repeat center center;
                        background-size: contain;
                        top: calc(50% - pcw(21));
                        left: calc(50% - pcw(23));
                        z-index: 10;
                    }
                }
                .txt {
                    padding: pcw(48) pcw(80);
                    width: pcw(520);
                    .head {
                        color: var(--c-gry);
                        font-family: var(--ff-mar);
                    }
                    .ttl {
                        margin-bottom: pcw(24);
                        font-size: pcw(20);
                        font-weight: 600;
                    }
                }
                @include pc {
                    &:hover {
                        .img {
                            &::before {
                                opacity: 0;
                            }
                        }
                    }
                }
                @include sp {
                    width: spw(311);
                    flex-direction: column;
                    .img {
                        width: 100%;
                        &::after {
                            width: spw(46);
                            height: spw(32);
                            top: calc(50% - spw(21));
                            left: calc(50% - spw(23));
                        }
                    }
                    .txt {
                        padding: spw(22);
                        width: 100%;
                        .ttl {
                            margin-bottom: spw(16);
                            font-size: spw(20);
                        }
                    }
                }
            }
        }
    }
    &-company {
        .hero {
            .above {
                // &::after {
                //     background-image: url(../img/company/mv-pc.png);
                //     @include sp {
                //         background-image: url(../img/company/mv-sp.png);
                //     }
                // }
            }
        }
        .inner {
            margin: 0 auto;
            width: pcw(722);
            border: none;
            @include sp {
                width: spw(306);
            }
        }
        .information {
            padding-bottom: pcw(300);
            .ttl-img {
                margin-bottom: pcw(136);
                position: relative;
                // background-image: url(../img/company/information-img01.png);
                h2 {
                    position: relative;
                    z-index: 1;
                }
                .img {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                @include sp {
                    margin-bottom: spw(80);
                }
            }
            table {
                th, td {
                    padding: pcw(20) 0;
                    font-weight: normal;
                    @include sp {
                        padding: 0;
                        display: block;
                    }
                }
                th {
                    width: pcw(240);
                    @include sp {
                        padding-bottom: spw(5);
                        width: 100%;
                    }
                }
                td {
                    width: pdw(480);
                    @include sp {
                        padding-bottom: spw(30);
                        width: 100%;
                    }
                }
            }
        }
        .office {
            margin-top: pcw(200);
            position: relative;
            overflow: hidden;
            h3 {
                margin: 0 auto pcw(104);
                width: pcw(722);
                font-size: pcw(32);
                font-family: var(--ff-mar);
                @include sp {
                    width: spw(306);
                    font-size: spw(24);
                }
            }
            .slider {
                margin: 0 auto pcw(64);
                width: pcw(722);
                position: static;
                .slick-list {
                    overflow: initial;
                }
                .slick-slide {
                    margin-right: pcw(32);
                }
                @include sp {
                    margin-bottom: spw(48);
                    width: spw(306);
                }
            }
            .slick-prev, .slick-next {
                width: pcw(20);
                height: pcw(20);
                position: absolute;
                top: pcw(5);
                &::after {
                    border-color: var(--c-brn);
                }
            }
            .slick-prev {
                right: pcw(200);
            }
            .slick-next {
                right: pcw(160);
            }
            @include sp {
                .slick-prev, .slick-next {
                    width: spw(10);
                    height: spw(10);
                    top: spw(12);
                }
                .slick-prev {
                    right: spw(60);
                }
                .slick-next {
                    right: spw(40);
                }
            }
            .address {
                margin-bottom: pcw(24);
                font-size: pcw(16);
                @include sp {
                    margin-bottom: spw(24);
                    font-size: spw(16);
                }
            }
            .btn {
                width: pcw(160);
                height: pcw(40);
                line-height: pcw(40);
                border-radius: 0;
                @include sp {
                    width: spw(160);
                    height: spw(40);
                    line-height: spw(40);
                }
            }
        }
        .history {
            padding-bottom: pcw(160);
            background: var(--c-c-beg);
            .ttl-img {
                margin-bottom: pcw(136);
                // background-image: url(../img/company/history-img01.png);
                position: relative;
                top: pcw(-80);
                overflow: hidden;
                h2 {
                    position: relative;
                    z-index: 1;
                }
                .img {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                @include sp {
                    margin-bottom: spw(80);
                }
            }
            h3 {
                margin-bottom: pcw(48);
                font-family: var(--ff-mar);
                font-size: pcw(32);
                @include sp {
                    font-size: spw(24);
                }
            }
            th, td {
                padding: pcw(12) 0;
                font-size: pcw(16);
                @include sp {
                    padding: spw(16) 0;
                }
            }
            th {
                width: pcw(260);
                font-family: var(--ff-mar);
                @include sp {
                    width: spw(90);
                    font-size: spw(16);
                }
            }
            td {
                @include sp {
                    font-size: spw(14);
                }
            }
        }
    }
    &-contact {
        .head {
            padding: pcw(182) pcw(82) pcw(56);
            background: url(../img/bg/head.png);
            @include sp {
                padding: spw(120) spw(32) spw(40);
            }
        }
        .wrap {
            margin: 0 auto;
            padding: pcw(142) 0;
            width: pcw(760);
            @include sp {
                padding: spw(60) spw(20);
                width: auto;
            }
            h3 {
                font-size: pcw(32);
                font-weight: 700;
                @include sp {
                    font-size: spw(32);
                }
            }
            .step {
                margin-bottom: pcw(64);
                font-size: pcw(12);
                display: flex;
                justify-content: flex-start;
                @include sp {
                    margin-bottom: spw(64);
                    font-size: spw(12);
                }
                li {
                    color: var(--c-gry);
                    &:not(:last-child) {
                        margin-right: pcw(15);
                        padding-right: pcw(15);
                        position: relative;
                        &::after {
                            @include content;
                            width: 1px;
                            height: 100%;
                            background: var(--c-s-beg);
                            top: 0;
                            right: 0;
                            transform: rotate(10deg);
                        }
                    }
                    &.cr {
                        color: var(--c-m-brn);
                    }
                    @include sp {
                        &:not(:last-child) {
                            margin-right: spw(15);
                            padding-right: spw(15);
                        }
                    }
                }
            }
            .container {
                dt {
                    margin-bottom: pcw(5);
                    font-size: pcw(16);
                    &.requied {
                        &::after {
                            content: '※必須';
                            margin-left: pcw(10);
                            font-size: pcw(12);
                        }
                    }
                }
                dd {
                    margin-bottom: pcw(48);
                    font-size: pcw(16);
                    word-break: break-all;
                }
                @include sp {
                    dt {
                        margin-bottom: spw(5);
                        font-size: spw(16);
                        &.requied {
                            &::after{
                                font-size: spw(12);
                            }
                        }
                    }
                    dd {
                        margin-bottom: spw(48);
                        font-size: spw(14);
                    }
                }
                .post {
                    span {
                        display: inline-block;
                        &:not(:last-child) {
                            margin-right: pcw(36);
                            position: relative;
                            @include sp {
                                margin-right: spw(32);
                            }
                            &::after {
                                @include content;
                                width: pcw(16);
                                height: 1px;
                                background: var(--c-brn);
                                top: 50%;
                                right: pcw(-26);
                                @include sp {
                                    width: spw(16);
                                    right: spw(-26);
                                }
                            }
                        }
                    }
                }
                .tel {
                    span {
                        display: inline-block;
                        &:not(:last-child) {
                            margin-right: pcw(36);
                            position: relative;
                            &::after {
                                @include content;
                                width: pcw(16);
                                height: 1px;
                                background: var(--c-brn);
                                top: 50%;
                                right: pcw(-26);
                            }
                            @include sp {
                                margin-right: spw(36);
                                &::after {
                                    width: spw(16);
                                    right: spw(-26);
                                }
                            }
                        }
                    }
                }
                .agree {
                    text-align: center;
                    .policy {
                        margin-bottom: pcw(20);
                        padding: pcw(10) pcw(20);
                        height: pcw(280);
                        text-align: left;
                        border: 1px solid var(--c-brn);
                        border-radius: 2px;
                        overflow-y: scroll;
                        scrollbar-color: var(--c-s-beg) var(--c-wht);
                        scrollbar-width: thin;
                        @include sp {
                            margin-bottom: spw(20);
                            padding: spw(10) spw(20);
                            height: spw(280);
                        }
                    }
                }
                .btn-area {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: pcw(20);
                    @include sp {
                        gap: spw(20);
                    }
                }
            }
        }
        .mw_wp_form_input {
          .only-confirm {
            display: none;
          }
          .step {
            li {
                &:nth-of-type(1) {
                    color: var(--c-m-brn);
                }
            }
          }
          .post {
              span {
                  &:nth-of-type(1) {
                      width: pcw(91);
                  }
                  &:nth-of-type(2) {
                      width: pcw(91);
                  }
                  @include sp {
                    &:nth-of-type(1) {
                        width: spw(80);
                    }
                    &:nth-of-type(2) {
                        width: spw(80);
                    }
                  }
              }
          }
          .tel {
              span {
                width: pcw(91);
                @include sp {
                    width: spw(80);
                }
              }
          }
          .mail {
              input[type=text] {
                width: pcw(440);
                @include sp {
                    width: 100%;
                }
              }
          }
        }
        .mw_wp_form_confirm {
          .only-input {
            display: none;
          }
          .step {
            li {
                &:nth-of-type(2) {
                    color: var(--c-m-brn);
                }
            }
          }
        }
        .mw_wp_form_complete {
          p {
            margin-bottom: pcw(20);
            @include sp {
                margin-bottom: spw(20);
            }
          }
          @include pc {
              .call {
                  pointer-events: none;
                }
          }
          .step {
            li {
                &:nth-of-type(3) {
                    color: var(--c-m-brn);
                }
            }
          }
        }
    }
    &-policy {
        .head {
            padding: pcw(182) pcw(82) pcw(56);
            background: url(../img/bg/head.png);
            @include sp {
                padding: spw(120) spw(32) spw(40);
            }
        }
        .wrap {
            margin: 0 auto;
            padding: pcw(142) 0;
            width: pcw(760);
            @include sp {
                padding: spw(60) spw(20);
                width: auto;
            }
            .lead {
                margin-bottom: pcw(80);
                @include sp {
                    margin-bottom: spw(80);
                }
            }
            .block {
                margin-bottom: pcw(80);
                @include sp {
                    margin-bottom: spw(60);
                }
                h3 {
                    margin-bottom: pcw(20);
                    font-size: pcw(16);
                    font-weight: 700;
                    @include sp {
                        font-size: spw(16);
                    }
                }
                p {
                    margin-bottom: pcw(20);
                    @include sp {
                        margin-bottom: spw(20);
                    }
                }
            }
        }
    }
    &-404 {
        padding: 100px 0;
        text-align: center;
        font-size: 60px;
        font-weight: 700;
    }
}