:root {
	--c-blk: #000;
	--c-gry: #958a88;
	--c-wht: #fff;
	--c-c-beg: #fffdf5;
	--c-beg: #f2efe6;
	--c-s-beg: #dddbd3;
	--c-brn: #594745;
	--c-m-brn: #332b2a;
	--c-red: #f13d2a;
	--c-shadow: #f76c53;
	--ff-mar: 'Marcellus', serif; // 400
	--ff-sans: 'Noto Sans JP', sans-serif;
	--ff-serif: 'Noto Serif JP', serif; // 200,300,400,500,600,700,900
}
// 100 Thin
// 200 Extra-light
// 300 Light
// 400 Regular
// 500 Medium
// 600 Semi-bold
// 700 Bold
// 800 Extra-bold
// 900 Black
html, body {
	width: 100%;
	height: 100%;
	scroll-behavior: smooth;
	scroll-padding-top: 0;
}
body {
	line-height: 1.7;
	font-size: pcw(14);
	font-family: var(--ff-serif);
	font-weight: 400;
	color: var(--c-m-brn);
	background: var(--c-wht);
	@include sp {
		font-size: spw(14);
	}
}
img, svg {
	width: 100%;
	height: 100%;
	object-fit: contain;
	vertical-align: middle;
}