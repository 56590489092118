input[type=button], input[type=text], input[type=submit] {
  -webkit-adiappearance: none;
  border-radius: 0;
}
input[type=text], textarea, select {
  padding: pcw(10) pcw(20);
  height: pcw(56);
  border: 1px solid var(--c-brn);
  border-radius: 2px;
  @include sp {
    padding: spw(10);
    height: spw(56);
  }
}
input[type=text], textarea, select {
  width: 100%;
}
textarea {
  height: pcw(280);
  @include sp {
    height: spw(160);
  }
}
select {
  cursor: pointer;
}
input[type=submit] {
  width: pcw(200);
  text-align: center;
  display: block;
  position: relative;
  z-index: 9;
  @include sp {
    width: 100%;
  }
}
label {
  cursor: pointer;
}
input[type=radio], input[type=checkbox] {
  visibility: hidden;
  position: absolute;
  opacity: 0;
}

.mw_wp_form_input {
  .mwform-radio-field {
    label {
      width: pcw(104);
      height: pcw(56);
      line-height: pcw(56);
      border: 1px solid var(--c-s-beg);
      border-radius: 2px;
      text-align: center;
      display: inline-block;
      &:has(input[type=radio]:checked) {
        border-color: var(--c-brn);
      }
    }
    span {
      padding-left: pcw(22);
      position: relative;
      &::before, &::after {
        @include content;
        width: pcw(12);
        height: pcw(12);
        border: 1px solid var(--c-gry);
        border-radius: 50%;
        top: calc(50% - pcw(6));
        left: 0;
      }
    }
    input[type=radio]:checked {
      & + span {
        &::after {
          background: var(--c-gry);
        }
      }
    }
    @include sp {
      label {
        width: spw(104);
        height: spw(56);
        line-height: spw(56);
      }
      span {
        padding-left: spw(22);
        &::before, &::after {
          width: spw(12);
          height: spw(12);
          top: calc(50% - spw(6));
        }
      }
    }
  }
  .w-auto {
    .mwform-radio-field {
      label {
        width: auto;
        padding: 0 10px;
      }
    }
  }
  .mwform-checkbox-field {
    span {
      padding-left: pcw(22);
      position: relative;
      &::before {
        @include content;
        width: pcw(16);
        height: pcw(16);
        border: 1px solid var(--c-brn);
        border-radius: 2px;
        top: calc(50% - pcw(8));
        left: 0;
      }
      &::after {
        @include content;
        width: pcw(10);
        height: pcw(4);
        border: 1px solid var(--c-brn);
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
        top: pcw(9);
        left: pcw(4);
        opacity: 0;
      }
    }
    input[type=checkbox]:checked {
      & + span {
        &::after {
          opacity: 1;
        }
      }
    }
    @include sp {
      span {
        padding-left: spw(22);
        &::before {
          width: spw(16);
          height: spw(16);
          top: calc(50% - spw(8));
        }
        &::after {
          width: spw(10);
          height: spw(4);
          top: spw(9);
          left: spw(4);
        }
      }
    }
  }
  select {
    -webkit-appearance: none;
    appearance: none;
    overflow: hidden;
    display: block;
  }
  .select {
    width: pcw(320);
    position: relative;
    overflow: hidden;
    select {
      padding-right: 20px;
      position: relative;
    }
    &::before, &::after {
      @include content;
      width: pcw(10);
      height: 1px;
      background: var(--c-brn);
      top: calc(50% + pcw(5));
    }
    &::before {
      right: pcw(25);
      transform-origin: right center;
      transform: rotate(45deg);
    }
    &::after {
      right: pcw(15);  
      transform-origin: left center;
      transform: rotate(-45deg);
    }
    @include sp {
      width: spw(320);
      &::before, &::after {
        width: spw(10);
        top: calc(50% + spw(5));
      }
      &::before {
        right: spw(25);
      }
      &::after {
        right: spw(15);
      }
    }
  }
}

