@mixin sp($w: $bp) {
	@media screen and (max-width: $w) {
		@content;
	}
}
@mixin pc($w: $bp) {
	@media screen and (min-width: $w + 1) {
		@content;
	}
}
@mixin flex($wrap: wrap, $h: space-between, $v: center) {
	display: flex;
	flex-wrap: $wrap;
	justify-content: $h;
	align-items: $v;
}
@mixin ts($property: all, $duration: .3s, $delay: 0s) {
	transition: $property $duration $delay;
}
@mixin content($position: absolute) {
	content: "";
	display: block;
	position: $position;
}
@mixin btn($ff: var(--ff-mar), $bg: transparent, $bc: var(--c-m-brn), $rad: 2px) {
	font-family: var(--ff-mar);
	text-align: center;
	background: $bg;
	border: 1px solid $bc;
	border-radius: $rad;
	display: block;
    &::before {
        @include content;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        background: #000;
        background-image: url(../img/obj/bg-red.png);
        opacity: 0;
        top: -1px;
        left: -1px;
        @include ts;
    }
    &::after {
        @include content;
        width: 60%;
		height: 60%;
		background: var(--c-shadow);
		border-radius: 2px;
		left: 20%;
		top: 20%;
		opacity: 0;
		filter: blur(10px);
		@include ts;
    }
    @include pc {
        &:hover {
            color: var(--c-wht);
            &::before {
                opacity: 1;
            }
			&::after {
				top: 50%;
				opacity: 0.7;
			}
        }
    }
}