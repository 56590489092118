@include sp {
	.noSP { display: none !important; }
}
@include pc {
	.noPC { display: none !important; }
}

@for $i from 0 through 100 {
	.w-#{$i} { width: $i*1% !important }
}
@for $i from 0 through 100 {
	.mb-#{$i} {
		margin-bottom: spw($i*2) !important;
		@media screen and (min-width: $bp+1) {
			margin-bottom: $i*1px !important;
		}
	}
}
@include sp {
	@for $i from 0 through 100 {
		.sp-w-#{$i} { width: $i*1% !important; }
	}
	@for $i from 0 through 100 {
		.sp-mb-#{$i} { margin-bottom: $i*1px !important; }
	}
}
@include pc {
	@for $i from 0 through 100 {
		.pc-w-#{$i} { width: $i*1% !important; }
	}
	@for $i from 0 through 100 {
		.pc-mb-#{$i} { margin-bottom: $i*1px !important; }
	}
}

@import "./anime";
@import "./input";
@import "./img";
@import "./list";
@import "./txt";
@import "./parts";
@import "./global";
@import "./page";
@import "./zindex";