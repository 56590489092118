.l-cols {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	&.rev { flex-direction: row-reverse; }
	&.left { justify-content: flex-start; }
	&.center { justify-content: center; }
	&.right { justify-content: flex-end; }
	&.top { align-items: flex-start; }
	&.middle { align-items: center; }
	&.bottom { align-items: flex-end; }
	.self-top { align-self: flex-start; }
	.self-middle { align-self: center; }
	.self-bottom { align-self: flex-end; }
	@for $i from 1 through 6 {
		.order-#{$i} { order: $i }
	}
}
@include sp {
	.sp {
		&-l-cols {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			&.rev { flex-direction: row-reverse; }
			&.left { justify-content: flex-start; }
			&.center { justify-content: center; }
			&.right { justify-content: flex-end; }
			&.top { align-items: flex-start; }
			&.middle { align-items: center; }
			&.bottom { align-items: flex-end; }
			.self-top { align-self: flex-start; }
			.self-middle { align-self: center; }
			.self-bottom { align-self: flex-end; }
			@for $i from 1 through 6 {
				&-order-#{$i} { order: $i }
			}
		}
		&-left { justify-content: flex-start; }
		&-center { justify-content: center; }
		&-right { justify-content: flex-end; }
		&-top { align-items: flex-start; }
		&-middle { align-items: center; }
		&-bottom { align-items: flex-end; }
	}
}
@include pc {
	.pc {
		&-l-cols {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			&.rev { flex-direction: row-reverse; }
			&.left { justify-content: flex-start; }
			&.center { justify-content: center; }
			&.right { justify-content: flex-end; }
			&.top { align-items: flex-start; }
			&.middle { align-items: center; }
			&.bottom { align-items: flex-end; }
			.self-top { align-self: flex-start; }
			.self-middle { align-self: center; }
			.self-bottom { align-self: flex-end; }
			@for $i from 1 through 6 {
				&-order-#{$i} { order: $i }
			}
		}
		&-left { justify-content: flex-start; }
		&-center { justify-content: center; }
		&-right { justify-content: flex-end; }
		&-top { align-items: flex-start; }
		&-middle { align-items: center; }
		&-bottom { align-items: flex-end; }
	}
}